import { combineReducers } from 'redux';
import settingsReducer from './slices/settings';
import contactsReducer from './slices/contacts';
import tagsReducer from './slices/tags';
import operationsReducer from './slices/operations';
import actionsReducer from './slices/actions';
import reportsReducer from './slices/reports';
import tasksReducer from './slices/tasks';
import leasesReducer from './slices/leases';
import filtersReducer from './slices/filters';
import investorsReducer from './slices/investors';
import currenciesReducer from './slices/currencies';
import mortgagesReducer from './slices/mortgages';
import organizationsReducer from './slices/organizations';
import storage from '../storage';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings'],
};

const rootReducer = combineReducers({
  settings: settingsReducer,
  contacts: contactsReducer,
  tags: tagsReducer,
  operations: operationsReducer,
  actions: actionsReducer,
  reports: reportsReducer,
  leases: leasesReducer,
  filters: filtersReducer,
  tasks: tasksReducer,
  investors: investorsReducer,
  currencies: currenciesReducer,
  mortgages: mortgagesReducer,
  organizations: organizationsReducer,
});

export { rootPersistConfig, rootReducer };
