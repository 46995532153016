import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  mortgage: {},
};

const slice = createSlice({
  name: 'mortgages',
  initialState,
  reducers: {
    getMortgages(state, action) {
      state.data = action.payload;
    },
    getOneMortgage(state, action) {
      state.mortgage = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getMortgages, getOneMortgage } = slice.actions;
