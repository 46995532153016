import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

const slice = createSlice({
  name: 'currencies',
  initialState,
  reducers: {
    getCurrencies(state, action) {
      state.data = action.payload;
    },
    getOneCurrency(state, action) {
      state.investor = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getCurrencies, getOneCurrency } = slice.actions;
