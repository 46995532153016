import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

const slice = createSlice({
  name: 'leases',
  initialState,
  reducers: {
    setLeases(state, action) {
      state.data = action.payload;
    },
  },

});

export default slice.reducer;
export const { setLeases } = slice.actions;