import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import frLocale from 'date-fns/locale/fr';

import { Head, Loader } from '@components/common'
import NotistackProvider from '@components/common/NotistackProvider'

import { AuthProvider, ProtectRoute } from '@contexts/auth'
import { OperationProvider } from '@contexts/operation'
import { TranslationProvider } from '@contexts/translation'

import { ThemeConfig } from '@theme/index'

import { store, persistor } from '@redux/store'
import { StateMachineProvider, createStore } from "little-state-machine";
import loadable from "@loadable/component"; // npm install @loadable/component

import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import Cookies from 'js-cookie'

export default function MyApp(props) {
  const { Component, pageProps } = props

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  createStore({});

  return (
    <>
      <Head />
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <ThemeConfig>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
              <NotistackProvider>
                <AuthProvider>
                  <TranslationProvider>
                    <OperationProvider>
                      <ProtectRoute>
                        <StateMachineProvider>
                          <Component {...pageProps} />
                        </StateMachineProvider>
                      </ProtectRoute>
                    </OperationProvider>
                  </TranslationProvider>
                </AuthProvider>
              </NotistackProvider>
            </LocalizationProvider>

          </ThemeConfig>
        </PersistGate>
      </Provider>
    </>
  )
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
}
