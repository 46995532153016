import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: {
        operation: null,
        date: null,
        lease: null
    }
};

const slice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setFilters(state, action) {
            state.data = { ...state.data, ...action.payload }
        },
    },

});

export default slice.reducer;
export const { setFilters } = slice.actions;