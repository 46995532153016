import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    surfaces: [],
    rentroll: []
  },
  key: ''
};

const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setReports(state, action) {
      state.data = { ...state.data, ...action.payload.data };
      state.isLoading = false;
    },
    setReportKey(state, action) {
      state.key = action.payload;
    },
    setReportsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },

});

export default slice.reducer;
export const { setReports, setReportKey, setReportsLoading } = slice.actions;