import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  contact: {},
};

const slice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    getContacts(state, action) {
      state.data = action.payload;
    },
    getOneContact(state, action) {
      state.contact = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getContacts, getOneContact } = slice.actions;
