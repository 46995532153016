import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  listsViewModeId: 0,      // Tasks 
  tasksListsViewModeId: 0   // Kanban
};

const slice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setListsViewModeId(state, action) {
      state.listsViewModeId = action.payload
    },
    setTasksListsViewModeId(state, action) {
      state.tasksListsViewModeId = action.payload
    }
  }
})

export default slice.reducer;
export const { setListsViewModeId, setTasksListsViewModeId } = slice.actions;
