import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

const slice = createSlice({
  name: 'operations',
  initialState,
  reducers: {
    getOperations(state, action) {
      state.data = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getOperations } = slice.actions;
